.portfolio-section {
  padding: 60px 20px;
  text-align: center;
  background-color: #f9f9f9;
}

.portfolio-section h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
  font-weight: bold;
}

.portfolio-section .line {
  width: 50px;
  height: 4px;
  background: #333;
  margin: 0 auto 40px;
}

.portfolio-project-info {
  display: flex;
  align-items: center;
  max-width: 90%;
  margin: 0 auto;
  text-align: left;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.portfolio-images {
  position: relative;
  flex: 1;
}

.portfolio-image-big {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.portfolio-image-small {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 150px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.portfolio-content {
  flex: 2;
  padding-left: 20px;
}

.portfolio-content h3 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
  font-weight: bold;
}

.portfolio-content p {
  font-size: 1.1em;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #666;
}

.portfolio-cta-section {
  background: #f4f4f4;
  padding: 60px 20px;
  text-align: center;
}

.portfolio-cta-section h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
  font-weight: bold;
}

.portfolio-cta-section p {
  font-size: 1.1em;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #666;
}

.portfolio-cta-button {
  display: inline-block;
  padding: 15px 30px;
  background: #333;
  color: #fff;
  text-decoration: none;
  font-size: 1.1em;
  border-radius: 5px;
  transition: background 0.3s ease;
}

.portfolio-cta-button:hover {
  background: #555;
}

/* Ensure h2 elements are centered */
.completed-projects-section h2,
.in-progress-projects-section h2,
.portfolio-cta-section h2 {
  margin-top: 20px;
  text-align: center;
  font-weight: bold;
}

/* Responsive Styles */
@media (max-width: 768px) {


  .portfolio-project-info {
    flex-direction: column;
  }

  .portfolio-images {
    margin-bottom: 20px;
  }

  .portfolio-content {
    padding-left: 0;
  }

  .portfolio-section h2 {
    font-size: 2em;
  }

  .portfolio-content h3 {
    font-size: 1.5em;
  }

  .portfolio-content p {
    font-size: 1em;
  }

  .portfolio-cta-section h2 {
    font-size: 2em;
  }

  .portfolio-cta-section p {
    font-size: 1em;
  }

  .portfolio-cta-button {
    font-size: 1em;
    padding: 10px 20px;
  }
}