



.cta {
  background-color: #e1306c;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  border-radius: 25px;
  transition: background-color 0.3s ease;
}

.cta:hover {
  background-color: #c2185b;
}

/* Contact Intro */
.contact-intro {
  margin: 20px 0;
  text-align: center;
  font-size: 1.1em;
  animation: fade-in 1s ease-in-out;
}

/* Contact Form */
.contact-form {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 4px 20px rgb(0 0 0 / 10%);
  transition: transform 0.3s, box-shadow 0.3s;
}

.contact-form:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgb(0 0 0 / 20%);
}

.contact-form label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.contact-form input,
.contact-form textarea {
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1em;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgb(0 123 255 / 50%);
  outline: none;
}

.contact-form button {
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s, transform 0.3s;
}

.contact-form button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

/* Contact Details */
.contact-details {
  margin: 40px 0;
  text-align: center;
}

.contact-details h2 {
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #007bff;
}

.additional-info-wrapper {
  display: flex;
  justify-content: center;  /* Center horizontally */
  align-items: center;      /* Center vertically */
  margin: 20px 0;
}

.additional-info {
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px;             /* Margin for spacing around the box */
  max-width: 600px;        /* Maximum width for the box */
  width: 100%;             /* Full width of the parent container */
  transition: transform 0.3s ease-in-out;
}

.additional-info:hover {
  transform: translateY(-5px); /* Hover effect */
}

.additional-info h2 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 15px;
  text-align: center;         /* Center text */
}

.additional-info ul {
  list-style-type: none;      /* Remove default list styling */
  padding: 0;
}

.additional-info li {
  margin: 10px 0;
  font-size: 1rem;
  color: #555;
  display: flex;
  align-items: center;        /* Align items vertically */
}

.additional-info li::before {
  content: '✔️';
  color: #0d6efd;             /* Color for the check mark */
  font-size: 1.2rem;
  margin-right: 10px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .additional-info {
    padding: 15px;
  }

  .additional-info h2 {
    font-size: 1.5rem;
  }

  .additional-info li {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .additional-info {
    padding: 10px;
  }

  .additional-info h2 {
    font-size: 1.3rem;
  }

  .additional-info li {
    font-size: 0.85rem;
  }
}

/* Social Media */
.social-media {
  margin: 40px 0;
  text-align: center;
}

.social-media h2 {
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #007bff;
}

.social-media ul {
  list-style-type: none;
  padding: 0;
}

.social-media ul li {
  display: inline-block;
  margin: 0 10px;
}

.social-media a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s, transform 0.3s;
}

.social-media a:hover {
  color: #0056b3;
  transform: scale(1.1);
}

/* Keyframes for Animation */
@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive Styles */
@media (width <= 768px) {
  .contact-intro {
    font-size: 1em;
  }

  .contact-form {
    width: 90%;
    padding: 20px;
  }

  .contact-details,
  .additional-info,
  .social-media {
    margin: 20px 0;
  }

  .contact-details h2,
  .additional-info h2,
  .social-media h2 {
    font-size: 1.3em;
  }
}

/* Paragraph Style */
.cpara {
  font-size: 1.2em;
  line-height: 1.6;
  color: #333;
  padding: 20px;
  border-left: 5px solid #007bff;
  background-color: #f9f9f9;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
  margin: 20px 0;
}

/* Hover Effect */
.cpara:hover {
  background-color: #e9f5ff;
  transform: scale(1.02);
}

/* Contact Heading Style */
.contactheading {
  font-size: 1.5em;
  font-weight: bold;
  color: #007bff;
  text-align: center;
  margin: 20px 0;
  padding: 10px;
  background-color: rgb(0 123 255 / 10%);
  border-radius: 5px;
  transition: color 0.3s ease, background-color 0.3s ease;
}

/* Hover Effect */
.contactheading:hover {
  color: #0056b3;
  background-color: rgb(0 123 255 / 20%);
}

/* Contact Form Popup */
.popup {
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
  padding: 16px 24px;
  border-radius: 8px;
  max-width: 400px;
  width: calc(100% - 40px);
  text-align: center;
  z-index: 1000;
  animation: fade-in 0.5s ease-in-out;
}

.popup.success {
  border-left: 6px solid #4caf50; /* Green border for success */
}

.popup.error {
  border-left: 6px solid #f44336; /* Red border for error */
}

.popup span {
  font-size: 16px;
  color: #333;
  display: inline-block;
  margin-right: 16px;
}

.close-btn {
  background: none;
  border: none;
  font-size: 18px;
  color: #888;
  cursor: pointer;
  padding: 0;
  line-height: 1;
}

.close-btn:hover {
  color: #333;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}

.contact-details {
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: 'Arial', sans-serif;
  color: #333;
}

.contact-details h2 {
  font-size: 24px;
  color: #1a73e8;
  margin-bottom: 15px;
}

.contact-details p {
  font-size: 16px;
  margin: 8px 0;
}

.contact-details a {
  color: #1a73e8;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.contact-details a:hover {
  color: #0056b3;
  text-decoration: underline;
}

.contact-details a:focus {
  outline: 2px solid #0056b3;
}

@media (max-width: 600px) {
  .contact-details {
    padding: 15px;
    font-size: 14px;
  }

  .contact-details h2 {
    font-size: 20px;
  }
}

