/* General Page Styles */
body {
  margin: 30px;
  padding: 10px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: #f8f9fa;
  color: #333;
  line-height: 1.6;
}

.services-page {
  width: 100%;
  overflow-x: hidden;
}

/* Hero Section */
.services-hero-section {
  position: relative;
  background: url("../../assets/Hero.jpg") no-repeat center center / cover;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  width: 100%;
}

.services-hero-overlay {
  position: absolute;
  inset: 0;
  background-color: rgb(0 0 0 / 50%);
}

.services-hero-content {
  position: relative;
  z-index: 2;
}

.services-hero-section h1 {
  font-size: 4rem;
  font-weight: bold;
  background: #fff;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  letter-spacing: 2px;
  text-shadow: 2px 4px 10px rgb(0 0 0 / 30%);
  transition: all 0.3s ease-in-out;
}

.services-hero-section p {
  font-size: 1.6rem;
  margin: 1.5rem 0;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.8;
  letter-spacing: 1px;
  text-shadow: 1px 2px 3px rgba(0, 0, 0, 0.3);
  animation: fadeInUp 1s ease-out 0.5s both;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Service Section */
.service-section {
  padding: 80px 20px;
  background-color: #ffffff;
}

.content-wrapper {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
  background-color: #fff;
  padding: 40px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.content-wrapper:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
}

.service-section h2 {
  font-size: 2.5rem;
  color: #2c3e50;
  font-weight: 700;
  margin-bottom: 15px;
  letter-spacing: 1px;
}

.service-section h3 {
  font-size: 1.8rem;
  color: #e74c3c;
  font-weight: 600;
  margin-bottom: 25px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.service-section p {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #555;
  margin-bottom: 20px;
  text-align: justify;
}

/* Services Grid Section */
.services {
  padding: 80px 20px;
  background-color: #f8f9fa;
}

.section-heading {
  text-align: center;
  font-size: 2.8rem;
  margin-bottom: 50px;
  color: #2c3e50;
  position: relative;
  padding-bottom: 15px;
}

.section-heading::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 4px;
  background-color: #3498db;
}

.service-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
  max-width: 1200px;
  margin: 0 auto;
}

.service-card {
  background-color: #fff;
  border-radius: 12px;
  padding: 30px;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.service-icon {
  font-size: 3rem;
  color: #3498db;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.service-card:hover .service-icon {
  transform: scale(1.1);
}

.service-card h2 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #2c3e50;
}

.service-card .line {
  width: 50px;
  height: 3px;
  background-color: #3498db;
  margin: 15px auto;
  transition: width 0.3s ease;
}

.service-card:hover .line {
  width: 70px;
}

.service-card p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .service-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

@media (max-width: 768px) {
  .services-hero-section {
    height: 60vh;
  }

  .services-hero-section h1 {
    font-size: 3rem;
  }

  .services-hero-section p {
    font-size: 1.3rem;
  }

  .service-section {
    padding: 60px 20px;
  }

  .service-section h2 {
    font-size: 2rem;
  }

  .service-section h3 {
    font-size: 1.5rem;
  }

  .service-section p {
    font-size: 1rem;
  }

  .section-heading {
    font-size: 2.3rem;
  }
}

@media (max-width: 576px) {
  .services-hero-section {
    height: 50vh;
  }

  .services-hero-section h1 {
    font-size: 2.5rem;
  }

  .services-hero-section p {
    font-size: 1.1rem;
  }

  .service-section {
    padding: 40px 15px;
  }

  .content-wrapper {
    padding: 30px 20px;
  }

  .service-section h2 {
    font-size: 1.8rem;
  }

  .service-section h3 {
    font-size: 1.3rem;
  }

  .section-heading {
    font-size: 2rem;
    margin-bottom: 30px;
  }

  .service-grid {
    gap: 20px;
  }

  .service-card {
    padding: 25px;
  }
}