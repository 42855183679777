/* Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

body {
  color: #333;
  background-color: #f9f9f9;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Hero Section */
.hero {
  position: relative;
  background: url("../../assets/hero2.jpg") no-repeat center center / cover;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  object-fit: cover; /* Ensure the image fits the container */
  margin-bottom: 20px;
}

.hero-overlay {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.hero-content {
  position: relative;
  z-index: 2;
}

.hero h1 {
  font-size: 4rem;
  font-weight: bold;
  background: #fff;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  letter-spacing: 2px;
  text-shadow: 2px 4px 10px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out;
}

.hero h1::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -10px;
  width: 0;
  height: 3px;
  background: #007bff;
  transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
}

.hero h1:hover::after {
  width: 100%;
  left: 0;
}

.hero p {
  font-size: 1.6rem;
  margin: 1.5rem 0;
  color: rgba(255, 255, 255, 0.85);
  line-height: 1.8;
  letter-spacing: 1px;
  text-shadow: 1px 2px 5px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out;
}

.hcta {
  padding: 1rem 2.5rem;
  background: rgba(0, 0, 0, 0.382);
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  border-radius: 50px;
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.3);
  transition: all 0.3s ease-in-out;
}

.hcta:hover {
  transform: scale(1.05)
}

/* Portfolio Section */
.homehomeportfolio {
  padding: 2rem;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 20px;
}

.homehomeportfolio h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.homehomeportfolio-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
}

.homehomeportfolio-item {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  width: calc(33.333% - 1rem);
  max-width: 500px;
}

.homehomeportfolio-item img {
  width: 100%;
  height: auto;
  display: block;
}

.homehomeportfolio-item h4 {
  margin: 1rem 0;
  font-size: 1.5rem;
  color: #fff;
  text-align: center;
  background-color: #1f85ad;
  padding: 10px 15px;
  border-radius: 20px;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.homeservices {
  padding: 50px 20px;
  text-align: center;
  background-color: #f9f9f9; /* Light background for contrast */
}

.homeservices h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #333;
  font-weight: 600;
}

.homeservice-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  gap: 30px; /* Space between items */
  max-width: 1200px; /* Max width for the grid */
  margin: 0 auto; /* Center the grid */
}

.homeservice-item {
  background-color: #fff; /* White background for each item */
  padding: 30px;
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  text-align: center;
  transition: transform 0.3s ease; /* Smooth hover effect */
}

.homeservice-item:hover {
  transform: translateY(-5px); /* Slight lift on hover */
}

.homeservice-item .icon {
  font-size: 2.5rem; /* Placeholder for icon size */
  color: #007bff; /* Blue color for icons */
  margin-bottom: 20px;
}

/* Icon Styles */
.homeservice-item .icon img {
  width: 50px; /* Fixed width */
  height: 50px; /* Fixed height */
  border-radius: 50%; /* Round shape */
  object-fit: cover; /* Ensure the image fits the container */
  margin-bottom: 20px; /* Space below the icon */
}

.homeservice-item h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #333;
  font-weight: 500;
}

.homeservice-item p {
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
  margin: 0;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .homeservice-items {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on medium screens */
  }
}

@media (max-width: 768px) {
  .homeservice-items {
    grid-template-columns: 1fr; /* 1 column on small screens */
  }

  .homeservices h2 {
    font-size: 2rem;
  }

  .homeservice-item h3 {
    font-size: 1.3rem;
  }

  .homeservice-item p {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .hero {
    height: 100vh; /* Ensure full viewport height on smaller screens */
    padding: 0 1rem;
  }

  .hero h1 {
    font-size: 2.5rem;
    letter-spacing: 1px;
  }

  .hero p {
    font-size: 1.2rem;
  }

  .hcta {
    font-size: 1rem;
    padding: 0.8rem 1.5rem;
  }
}

@media (max-width: 576px) {
  .hero {
    height: 100vh; /* Ensure full viewport height on very small screens */
    padding-top: 60px;
  }

  .hero h1 {
    font-size: 2rem;
  }

  .hero p {
    font-size: 1rem;
  }

  .hcta {
    font-size: 0.9rem;
    padding: 0.7rem 1.2rem;
  }
}

@media (max-width: 576px) {
  .hero {
    height: 95vh; /* Slightly reduce height for very small screens */
    padding-top: 60px;
  }

  .hero h1 {
    font-size: 2rem;
  }

  .hero p {
    font-size: 1rem;
  }

  .hcta {
    font-size: 0.9rem;
    padding: 0.7rem 1.2rem;
  }
}

/* Technology Images Section */
.tech-images {
  text-align: center;
  padding: 20px;
  background-color: #f9f9f9;
}

.tech-images h2 {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #333;
  font-weight: 600;
}

.tech-images-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  max-width: 1200px;
  margin: 0 auto;
}

.tech-item {
  width: 100px;
  height: 115px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  position: relative;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.826); /* Stronger shadow */
  overflow: hidden;
}

.tech-item:hover {
  transform: scale(1.1);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3); /* Increased shadow on hover */
}

.tech-item img {
  width: 50px;
  height: 50px;
  object-fit: contain;
  clip-path: inherit;
}

.tech-item p {
  margin-top: 10px;
  font-size: 0.6rem;
  color: #333;
}

.tech-item:hover {
  transform: scale(1.1);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
}

/* Testimonials Section */
.testimonials {
  padding: 4rem 2rem;
  background-color: #f9f9f9;
  text-align: center;
  border-radius: 20px;
}

.testimonials h2 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 3rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
}

.testimonials h2::after {
  content: "";
  display: block;
  width: 100px;
  height: 3px;
  background: linear-gradient(90deg, #1616176d, #393a3a95);
  margin: 0.5rem auto 0;
}

.testimonial-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.testimonial-item {
  background-color: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 500px;
  max-width: 100%;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  position: relative;
  text-align: left;
  color: #333;
  overflow: hidden;
}

.testimonial-item p {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 2;
}

.testimonial-item span {
  font-weight: bold;
  color: #007bff89;
  font-size: 1rem;
  display: block;
}

.testimonial-item::before {
  content: "\201C"; /* Unicode for left double quotation mark */
  font-size: 5rem;
  color: rgba(0, 123, 255, 0.1);
  position: absolute;
  top: -10px;
  left: -10px;
  z-index: 1;
}

.testimonial-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  background: linear-gradient(135deg, #2425267b, #2e2f3066);
  color: white;
}

.testimonial-item:hover p,
.testimonial-item:hover span {
  color: white;
}

/* Call to Action Section */
.cta-section {
  padding: 5rem;
  margin-bottom: 10px;
  background: rgba(22, 21, 21, 0.674);
  background-blend-mode: overlay;
  text-align: center;
  color: white;
  border-radius: 20px;
  overflow: hidden;
}

.cta-section h2 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
  letter-spacing: 2px;
}

.cta-section .cta {
  /* padding: 1rem 3rem; */
  background: linear-gradient(90deg, #1e1f1f, #272829);
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1ooterrem;
  border-radius: 50px;
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.3);
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  text-decoration: none;
  padding: 1rem;
}

.cta-section .cta:hover {
  background: linear-gradient(90deg, #1e1f1f, #2a2b2c);
  box-shadow: 0 6px 15px rgba(0, 123, 255, 0.5);
  transform: translateY(-3px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .cta-section {
    padding: 2rem;
  }

  .cta-section h2 {
    font-size: 1.8rem;
  }

  .cta-section .cta {
    font-size: 1rem;
    padding: 0.8rem 2rem;
  }
}

@media (max-width: 576px) {
  .cta-section {
    padding: 1rem;
  }

  .cta-section h2 {
    font-size: 1.6rem;
  }

  .cta-section .cta {
    font-size: 0.9rem;
    padding: 0.7rem 1.5rem;
  }
}

/* Our Process Section */
.our-process {
  padding: 50px 20px;
  background-color: #f9f9f9;
}

.our-process h2 {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 30px;
}

.process-steps {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.process-step {
  flex: 1 1 30%;
  max-width: 30%;
  background-color: #fff;
  margin: 10px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;
}

.process-step:hover {
  transform: translateY(-5px);
}

.process-step .icon {
  margin-bottom: 15px;
}

.process-step .icon img {
  width: 50px; /* Smaller icon size */
  height: 50px;
  object-fit: contain;
}

.process-step h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.process-step .line {
  width: 50px;
  height: 2px;
  background-color: #000;
  margin: 10px auto;
}

.process-step p {
  font-size: 1em;
  color: #666;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .process-step {
    flex: 1 1 45%;
    max-width: 45%;
  }
}

@media (max-width: 480px) {
  .process-step {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .our-process h2 {
    font-size: 2em;
  }

  .process-step h3 {
    font-size: 1.2em;
  }

  .process-step p {
    font-size: 0.9em;
  }
}

/* Why Choose Us Section */
.why-choose-us {
  padding: 60px 20px;
  background-color: #f9f9f9;
  text-align: center;
  border-radius: 20px;
}

.why-choose-us h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.why-choose-us-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.why-choose-us-item {
  flex: 1 1 300px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.why-choose-us-item:hover {
  transform: translateY(-5px);
}

.why-choose-us-item h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.why-choose-us-item p {
  font-size: 1rem;
  color: #555;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .hero h1 {
    font-size: 3.5rem;
  }

  .cta {
    font-size: 1.1rem;
  }
}

@media (max-width: 992px) {
  .hero {
    height: 50vh;
  }

  .hero h1 {
    font-size: 3rem;
  }

  .hero p {
    font-size: 1.4rem;
  }

  .cta {
    font-size: 1rem;
    padding: 0.8rem 2rem;
  }

  .testimonial-items {
    gap: 1.5rem;
  }

  .testimonial-item {
    width: 280px;
  }

  .cta-section h2 {
    font-size: 2rem;
  }

  .cta-section .cta {
    font-size: 1.2rem;
    padding: 0.9rem 2.5rem;
  }
}

@media (max-width: 768px) {
  .hero {
    height: 100vh;
    padding: 0 1rem;
  }

  .hero h1 {
    font-size: 2.5rem;
    letter-spacing: 1px;
  }

  .hero p {
    font-size: 1.2rem;
  }

  .cta {
    font-size: 1rem;
    padding: 0.8rem 1.5rem;
  }

  .homehomeportfolio-item {
    width: calc(50% - 1rem);
  }

  .homeservice-items {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .homeservice-item {
    width: 100%;
  }

  .testimonial-items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }

  .testimonial-item {
    width: 100%;
  }

  .cta-section h2 {
    font-size: 1.8rem;
  }

  .cta-section .cta {
    font-size: 1.1rem;
    padding: 0.8rem 2rem;
  }

  .process-steps {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .our-process {
    padding: 40px 15px;
  }

  .process-step {
    width: 90%;
    height: auto;
  }
}

@media (max-width: 576px) {
  .hero {
    height: 95vh;
    padding-top: 60px;
  }

  .hero h1 {
    font-size: 2rem;
  }

  .hero p {
    font-size: 1rem;
  }

  .cta {
    font-size: 0.9rem;
    padding: 0.7rem 1.2rem;
  }

  .homehomeportfolio-item {
    width: 100%;
  }

  .testimonials {
    padding: 3rem 1rem;
  }

  .testimonials h2 {
    font-size: 2rem;
  }

  .testimonial-items {
    display: grid;
    grid-template-columns: 1fr;
  }

  .cta-section {
    padding: 2rem 1rem;
  }

  .cta-section h2 {
    font-size: 1.6rem;
  }

  .cta-section .cta {
    font-size: 1rem;
    padding: 0.7rem 1.5rem;
  }
}

/* Why AspenCask Section */
.why-aspencask {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  background: #fff;
}

.why-aspencask .content {
  width: 50%;
}

.why-aspencask .content h2 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 10px;
}

.why-aspencask .highlight {
  color: #e74c3c;
}

.why-aspencask .content p {
  font-size: 18px;
  color: #333;
  line-height: 1.6;
}

.why-aspencask .features {
  margin-top: 20px;
}

.why-aspencask .feature {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

.why-aspencask .icon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.why-aspencask .black-bg {
  background-color: black;
}

.why-aspencask .red-bg {
  background-color: #e74c3c;
}

.why-aspencask .icon img {
  width: 30px;
  height: 30px;
}

.why-aspencask .feature h3 {
  font-size: 18px;
  font-weight: bold;
}

.why-aspencask .feature p {
  font-size: 14px;
  color: #555;
}

.why-aspencask .image-container {
  width: 45%;
  position: relative;
}

.why-aspencask .main-img {
  width: 80%;
  border-radius: 10px;
}

.why-aspencask .small-img {
  width: 300px;
  position: absolute;
  bottom: -20px;
  left: -50px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.why-aspencask .experience-box {
  position: absolute;
  bottom: 20px;
  right: 10px;
  background: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-left: 5px solid #e74c3c;
}

.why-aspencask .experience-box span {
  font-size: 28px;
  font-weight: bold;
  color: #000;
}

.why-aspencask .experience-box p {
  font-size: 14px;
  color: #555;
}

/* Responsive Design */
@media (max-width: 992px) {
  .why-aspencask {
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
  }

  .why-aspencask .content {
    width: 100%;
    margin-bottom: 20px;
  }

  .why-aspencask .image-container {
    width: 100%;
  }

  .why-aspencask .main-img {
    width: 100%;
  }

  .why-aspencask .small-img {
    width: 200px;
    bottom: -10px;
    left: -20px;
  }
}

@media (max-width: 576px) {
  .why-aspencask {
    padding: 20px;
  }

  .why-aspencask .content h2 {
    font-size: 28px;
  }

  .why-aspencask .content p {
    font-size: 16px;
  }

  .why-aspencask .feature h3 {
    font-size: 16px;
  }

  .why-aspencask .feature p {
    font-size: 12px;
  }

  .why-aspencask .small-img {
    width: 150px;
    bottom: -5px;
    left: -10px;
  }

  .why-aspencask .experience-box span {
    font-size: 24px;
  }

  .why-aspencask .experience-box p {
    font-size: 12px;
  }
}